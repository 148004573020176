<script setup>

</script>

<template>
<router-view></router-view>
</template>

<style scoped lang="less">

</style>