import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'



export default createStore({
  state: {
    navigationData:[],
    currentOperationStage:"内贸"

  },
  getters: {


  },
  mutations: {
    //设置导航数据
    setNavigationData(state , payload){
      // console.log(payload)
      state.navigationData = payload
    },

    SET_DOMESTIC_TRADE(state , payload){
      state.currentOperationStage = "内贸"
    },
    SET_FOREIGN_TRADE(state , payload){
      state.currentOperationStage = "外贸"
    },




  },
  actions: {



  },
  plugins: [
    createPersistedState({

    }),
  ],
  modules: {
  }
})






