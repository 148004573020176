<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style lang="less">
html, body , #app {
  width: 100%;
  height: 100%;
  background: #F0F0F0;
  margin: 0;
  padding: 0;
  font-family: "Microsoft YaHei";
}

</style>
<script setup>

</script>