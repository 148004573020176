<script setup>

import {House, Refresh, SwitchButton} from "@element-plus/icons-vue";
import {useRoute} from 'vue-router'
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {ElMessageBox} from "element-plus";
import {useRouter} from "vue-router";
// import {DynamicRoutes} from "@/router";
import locale from "element-plus/es/locale/lang/zh-cn";
import axiosInstance from "@/utils/http";

const route = useRoute()
const store = useStore()
const router = useRouter()



//接受父组件传来的值
const props = defineProps({
  isClose:{
    type:Boolean
  }
})


const activeMenu = ()=>{
  if(route.meta.activeMenu){
    return route.meta.activeMenu
  }
  return route.path
}




//声明导航数据，，，，，，获取vuex中的数据
// 创建一个响应式的对象
const state = reactive({
  navigationData: store.state.navigationData
})
// 监听state.count的变化
watch(() => store.state.navigationData, (newValue) => {
  state.navigationData = newValue;
})
// const navigationData = ref()







//退出登录
const login_out = ()=>{
  ElMessageBox.confirm(
      '确定要退出登录吗？',
      '提示',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
  )
      .then(() => {
        localStorage.removeItem('token')
        store.commit('setNavigationData' , [])
        store.commit('SET_DOMESTIC_TRADE' );
        axiosInstance.defaults.baseURL = 'http://192.168.50.32:10010/chinese-service';
        router.push('/login')
      })
      .catch(() => {})
}



</script>

<template>

  <el-menu
      active-text-color="#0D33A6"
      text-color="#333333"
      background-color="#ffffff"
     :default-active="activeMenu()"
      class="el-menu-vertical-demo"
      :collapse="props.isClose"
      :collapse-transition="true"
      :router="true"
      unique-opened
  >
    <el-menu-item>
      <img src="../../../assets/商家后台.svg" style="height: 20px;">
      <span v-show="!props.isClose" style="font-size: 18px;line-height: 50px;margin-left: 8px;">
        商城后台管理
      </span>
    </el-menu-item>

    <!--  静态路由，-->
    <el-menu-item index="/index/home">
      <i class="icon iconfont icon-shouye" style="font-size: 17px;margin-left: 3px;"></i>
      <template #title>
        <span class="sideText">首页</span>
      </template>
    </el-menu-item>

    <!--  动态路由，-->
    <template v-for="(item , index) in state.navigationData" :key="index">
          <el-sub-menu :index="'/' +item.name">
            <template #title>
              <i class="icon iconfont" :class="item.icon" style="font-size: 20px;"></i>
              <span class="sideText">{{item.meta.name}}</span>
            </template>

            <template v-for="(v , i) in item.children"  :key="i">
              <el-menu-item :index="'/index/'+ item.name  +'/'+ v.name">{{v.meta.name}}</el-menu-item>
            </template>

          </el-sub-menu>
    </template>

    <!--   静态路由， -->
    <el-menu-item  @click="login_out">
      <i class="icon iconfont icon-tuichudenglu" style="font-size: 17px;margin-left: 3px;"></i>
      <template #title>
        <span class="sideText" >退出登录</span>
      </template>
    </el-menu-item>


  </el-menu>

</template>

<style scoped lang="less">

@import "../../styleSheet.css";



//必须要有的部分
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}

//去除边框
.el-menu{
  border: none;
  height: 100%;

}
</style>
