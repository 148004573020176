// * 根据用户的权限不同，所看到的页面和可操作性也不同
// * admin----所有页面都可以看到
// * vip------具有vip的权限
// *
// * addRouter()
// *
// *准备动态加载的路由///////////*********************************************************外贸
export const ForeignDynamicRoutes = [
  {
    //商品管理模块
    path: "goods",
    name: "goods",
    icon: "icon-shangpinguanli",
    component: () => import("../../views/en-views/goods/IndexView.vue"),
    meta: {
      name: "商品管理",
    },
    children: [
      {
        path: "category",
        name: "category",
        component: () =>
          import("../../views/en-views/goods/CategoryManage.vue"),
        meta: {
          name: "商品分类",
        },
      },
      {
        path: "list",
        name: "list",
        component: () => import("../../views/en-views/goods/GoodsList.vue"),
        meta: {
          name: "商品列表",
        },
      },
      {
        path: 'keywordClassification',
        name: "keywordClassification",
        component:() => import('../../views/en-views/goods/keywordCategory.vue'),
        meta: {
          name: '关键词分类'
        }
      }
    ],
  },

  {
    //权限管理模块
    path: "permission",
    name: "permission",
    icon: "icon-quanxianguanli",
    component: () => import("../../views/permission/IndexView.vue"),
    meta: {
      name: "权限管理",
    },
    children: [
      {
        path: "user",
        name: "user",
        component: () => import("../../views/permission/UserManage.vue"),
        meta: {
          name: "用户管理",
        },
      },
      {
        path: "role",
        name: "role",
        component: () => import("../../views/permission/RoleManage.vue"),
        meta: {
          name: "角色管理",
        },
      },
      {
        path: "authority",
        name: "authority",
        component: () => import("../../views/permission/AuthorityManage.vue"),
        meta: {
          name: "权限设置",
        },
      },
    ],
  },

  {
    //主题管理模块
    path: "theme",
    name: "theme",
    icon: "icon-zhutiguanli",
    component: () => import("../../views/theme/IndexView.vue"),
    meta: {
      name: "主题管理",
    },
    children: [
      {
        path: "edit",
        name: "edit",
        component: () => import("../../views/theme/ThemeEdit.vue"),
        meta: {
          name: "主题编辑",
        },
      },
      {
        path: "set",
        name: "set",
        component: () => import("../../views/theme/ThemeSet.vue"),
        meta: {
          name: "主题设置",
        },
      },
    ],
  },
  {
    //文档中心模块
    path: "document",
    name: "document",
    icon: "icon-wendangzhongxin",
    component: () => import("../../views/document/IndexView.vue"),
    meta: {
      name: "文档中心",
    },
    children: [
      {
        path: "documentCategory",
        name: "documentCategory",
        component: () => import("../../views/document/DocumentCategory.vue"),
        meta: {
          name: "文档分类",
        },
      },
      {
        path: "articleManagement",
        name: "articleManagement",
        component: () => import("../../views/document/ArticleManagement.vue"),
        meta: {
          name: "文章管理",
        },
      },
      {
        path: "Approval",
        name: "Approval",
        component: () => import("../../views/document/Approval.vue"),
        meta: {
          name: "审批管理",
        },
      },
    ],
  },
  {
    //订单管理模块
    path: "order",
    name: "order",
    icon: "icon-dingdanguanli",
    component: () => import("../../views/order/IndexView.vue"),
    meta: {
      name: "订单管理",
    },
    children: [
      {
        path: "orderManage",
        name: "orderManage",
        component: () => import("../../views/order/OrderManage.vue"),
        meta: {
          name: "订单信息",
        },
      },
      {
        path: "refundManagement",
        name: "refundManagement",
        component: () => import("../../views/order/refundManagement.vue"),
        meta: {
          name: "退款管理",
        },
      },
    ],
  },
  {
    // 财务管理模块
    path: "financial",
    name: "financial",
    icon: "icon-caiwuguanli",
    component: () => import("../../views/financial/IndexView.vue"),
    meta: {
      name: "财务管理",
    },
    children: [
      {
        path: "shopTransaction",
        name: "shopTransaction",
        component: () => import("../../views/financial/shopTransaction.vue"),
        meta: {
          name: "店铺交易",
        },
      },
      {
        path: "platformTransaction",
        name: "platformTransaction",
        component: () =>
          import("../../views/financial/platformTransaction.vue"),
        meta: {
          name: "平台交易",
        },
      },
    ],
  },
  {
    // 客服模块
    path: "service",
    name: "service",
    icon: "icon-kefu",
    component: () => import("../../views/service/IndexView.vue"),
    meta: {
      name: "客服管理",
    },
    children: [
      {
        path: "serviceConversation",
        name: "serviceConversation",
        component: () => import("../../views/service/serviceConversation.vue"),
        meta: {
          name: "客服会话",
        },
      },
      {
        path: "serviceManagement",
        name: "serviceManagement",
        component: () => import("../../views/service/serviceManagement.vue"),
        meta: {
          name: "客服会话管理",
        },
      },
    ],
  },
  {
    // 优化模块
    path: "optimalManagement",
    name: "optimalManagement",
    icon: "icon-tubiaoshangchuanmoban",
    component: () => import("../../views/optimalManagement/IndexView.vue"),
    meta: {
      name: "优化管理",
    },
    children: [
      {
        path: "optimal",
        name: "optimal",
        component: () => import("../../views/optimalManagement/optimal.vue"),
        meta: {
          name: "优化",
        },
      },
    ],
  },
  {
    // 客户管理模块
    path: "customerManagement",
    name: "customerManagement",
    icon: "icon-kehuguanli",
    component: () => import("../../views/customerManagement/IndexView.vue"),
    meta: {
      name: "客户管理",
    },
    children: [
      {
        path: "membershipLevel",
        name: "membershipLevel",
        component: () =>
          import("../../views/customerManagement/membershipLevel.vue"),
        meta: {
          name: "会员等级分类",
        },
      },
      {
        path: "membershipManagement",
        name: "membershipManagement",
        component: () =>
          import("../../views/customerManagement/membershipManagement.vue"),
        meta: {
          name: "会员管理",
        },
      },
      {
        path: "membershipSettings",
        name: "membershipSettings",
        component: () =>
          import("../../views/customerManagement/membershipSettings.vue"),
        meta: {
          name: "会员设置",
        },
      },
    ],
  },
  {
    path: "storeManagement",
    name: "storeManagement",
    icon: "icon-dianpuguanli-copy",
    component: () => import("../../views/storeManagement/IndexView.vue"),
    meta: {
      name: "店铺管理",
    },
    children: [
      {
        path: "establishedStore",
        name: "establishedStore",
        component: () =>
          import("../../views/storeManagement/establishedStore.vue"),
        meta: {
          name: "已开通店铺",
        },
      },
      {
        path: "closedStore",
        name: "closedStore",
        component: () => import("../../views/storeManagement/closedStore.vue"),
        meta: {
          name: "已关闭店铺",
        },
      },
      {
        path: "storeAudit",
        name: "storeAudit",
        component: () => import("../../views/storeManagement/storeAudit.vue"),
        meta: {
          name: "店铺审核",
        },
      },
    ],
  },
  {
    path: "mail",
    name: "mail",
    icon: "icon-youxiang",
    component: () => import("../../views/mail/IndecView.vue"),
    meta: {
      name: "邮箱信息",
    },
  },
  {
    //营销设置
    path: "marketing",
    name: "marketing",
    icon: "icon-yingxiaoshezhi",
    component: () => import("../../views/marketing/IndexView.vue"),
    meta: {
      name: "营销设置",
    },
    children: [
      {
        path: "marketingCenter",
        name: "marketingCenter",
        component: () => import("../../views/marketing/marketingCenter.vue"),
        meta: {
          name: "营销中心",
        },
      },
    ],
  },
  {
    // 账户管理
    path: "accountManagement",
    name: "accountManagement",
    icon: "icon-zhanghuguanli",
    component: () => import("../../views/accountManagement/IndexView.vue"),
    meta: {
      name: "账户管理",
    },
    children: [
      {
        path: 'personalAccount',
        name: 'personalAccount',
        component:() => import('../../views/accountManagement/account.vue'),
        meta: {
          name: '个人账号管理'
        }
      }
    ],
  },
  {  // 分销管理
    path: 'distributionManagement',
    name: 'distributionManagement',
    icon: 'icon-fenxiaoguanli',
    component: () => import('../../views/distributionManagement/IndexView.vue'),
    meta: {
      name: '分销管理'
    },
    children: [
      {
        path: 'distributionSetting',
        name: 'distributionSetting',
        component: () => import('../../views/distributionManagement/distributionSetting.vue'),
        meta: {
          name: '分销管理设置'
        }
      },
      {
        path: 'distributionGoods',
        name: 'distributionGoods',
        component:() => import('../../views/distributionManagement/distributionGoods.vue'),
        meta: {
          name: '复制商品'
        }
      }
    ]
  }
];

// {//主题管理模块
//     path:'theme',
//     name:'theme',
//     icon:'icon-zhutiguanli',
//     component:()=>import('../views/theme/IndexView.vue'),
//     meta:{
//         name:'主题管理',
//     },
//     children: [
//         {
//             path:'edit',
//             name:'edit',
//             component:()=>import('../views/theme/ThemeEdit.vue'),
//             meta:{
//                 name:'主题编辑',
//             },
//         },
//         {
//             path:'set',
//             name:'set',
//             component:()=>import('../views/theme/ThemeSet.vue'),
//             meta:{
//                 name:'主题设置'
//             }
//         }
//     ]
// },
// {//文档中心模块
//     path: 'document',
//     name: 'document',
//     icon: 'icon-wendangzhongxin',
//     component: () => import('../views/document/IndexView.vue'),
//     meta:{
//         name:'文档中心',
//     },
//     children: [
//         {
//             path: 'documentCategory',
//             name: 'documentCategory',
//             component: () => import('../views/document/DocumentCategory.vue'),
//             meta:{
//                 name:'文档分类',
//             },
//         },
//         {
//             path: 'articleManagement',
//             name: 'articleManagement',
//             component: () => import('../views/document/ArticleManagement.vue'),
//             meta:{
//                 name:'文章管理',
//             },
//         },
//         {
//             path: 'Approval',
//             name: 'Approval',
//             component: () => import('../views/document/Approval.vue'),
//             meta: {
//                 name:'审批管理'
//             }
//         }
//     ]
// },
// {//订单管理模块
//     path: 'order',
//     name: 'order',
//     icon: 'icon-dingdanguanli',
//     component: () => import('../views/order/IndexView.vue'),
//     meta:{
//         name:'订单管理',
//     },
//     children: [
//         {
//             path: 'orderManage',
//             name: 'orderManage',
//             component: () => import('../views/order/OrderManage.vue'),
//             meta:{
//                 name:'订单信息',
//             },
//         },
//         {
//             path: 'refundManagement',
//             name: 'refundManagement',
//             component: () => import('../views/order/refundManagement.vue'),
//             meta: {
//                 name: '退款管理'
//             },
//         }
//
//     ]
// },
// {// 财务管理模块
//     path: 'financial',
//     name: 'financial',
//     icon: 'icon-caiwuguanli',
//     component: () => import('../views/financial/IndexView.vue'),
//     meta: {
//         name: '财务管理',
//     },
//     children: [
//         {
//             path: 'shopTransaction',
//             name: 'shopTransaction',
//             component: () => import('../views/financial/shopTransaction.vue'),
//             meta: {
//                 name: '店铺交易',
//             },
//         },
//         {
//             path: 'platformTransaction',
//             name: 'platformTransaction',
//             component: () => import('../views/financial/platformTransaction.vue'),
//             meta: {
//                 name: '平台交易'
//             }
//         }
//     ]
// },
// {// 客服模块
//     path: 'service',
//     name: 'service',
//     icon: 'icon-kefu',
//     component: () => import('@/views/service/IndexView.vue'),
//     meta: {
//         name: '客服管理',
//     },
//     children: [
//         {
//             path: 'serviceConversation',
//             name: 'serviceConversation',
//             component: () => import('../views/service/serviceConversation.vue'),
//             meta: {
//                 name: '客服会话'
//             }
//         },
//         {
//             path: 'serviceManagement',
//             name: 'serviceManagement',
//             component: () => import('../views/service/serviceManagement.vue'),
//             meta: {
//                 name: '客服会话管理'
//             }
//         },
//
//     ]
// },
// {// 优化模块
//     path: 'optimalManagement',
//     name: 'optimalManagement',
//     icon: 'icon-tubiaoshangchuanmoban',
//     component: () => import('../views/optimalManagement/IndexView.vue'),
//     meta: {
//         name: '优化管理',
//     },
//     children: [
//         {
//             path: 'optimal',
//             name: 'optimal',
//             component: () => import('../views/optimalManagement/optimal.vue'),
//             meta: {
//                 name: '优化',
//             }
//         }
//     ]
// },
// { // 客户管理模块
//     path: 'customerManagement',
//     name: 'customerManagement',
//     icon: 'icon-kehuguanli',
//     component: () => import('../views/customerManagement/IndexView.vue'),
//     meta: {
//         name: '客户管理'
//     },
//     children: [
//         {
//             path: 'membershipLevel',
//             name: 'membershipLevel',
//             component: () => import('../views/customerManagement/membershipLevel.vue'),
//             meta: {
//                 name: '会员等级分类'
//             }
//         },
//         {
//             path: 'membershipManagement',
//             name: 'membershipManagement',
//             component: () => import('../views/customerManagement/membershipManagement.vue'),
//             meta: {
//                 name: '会员管理'
//             }
//         },
//         {
//             path: 'membershipSettings',
//             name: 'membershipSettings',
//             component: () => import('../views/customerManagement/membershipSettings.vue'),
//             meta: {
//                 name: '会员设置'
//             }
//         }
//     ]
// },
// {
//     path: 'storeManagement',
//     name: 'storeManagement',
//     icon: 'icon-dianpuguanli-copy',
//     component: () => import('../views/storeManagement/IndexView.vue'),
//     meta: {
//         name: '店铺管理'
//     },
//     children: [
//         {
//             path: 'establishedStore',
//             name: 'establishedStore',
//             component: () => import('../views/storeManagement/establishedStore.vue'),
//             meta: {
//                 name: '已开通店铺'
//             }
//         },
//         {
//             path: 'closedStore',
//             name: 'closedStore',
//             component: () => import('../views/storeManagement/closedStore.vue'),
//             meta: {
//                 name: '已关闭店铺'
//             }
//         },
//         {
//             path: 'storeAudit',
//             name: 'storeAudit',
//             component: () => import('../views/storeManagement/storeAudit.vue'),
//             meta: {
//                 name: '店铺审核'
//             }
//         }
//     ]
// },
// {
//     path: 'mail',
//     name: 'mail',
//     icon: 'icon-youxiang',
//     component: () => import('../views/mail/IndecView.vue'),
//     meta: {
//         name: '邮箱信息'
//     },
// }
