<script setup>

import NavBarComponent from "@/views/index/components/NavBarComponent.vue";
import HeaderComponent from "@/views/index/components/HeaderComponent.vue";
import ContentComponent from "@/views/index/components/ContentComponent.vue";
import {ref} from "vue";


//初始状态设置右部为不折叠
const isClose = ref(false)
//子组件触发的change函数，改变折叠状态
const change = ()=>{
  isClose.value = !isClose.value
}


</script>

<template>

  <div class="common-layout">

    <el-container class="layout-box">
      <el-aside width="200px" class="aside">
        <el-scrollbar>
          <NavBarComponent :isClose="isClose"></NavBarComponent>
        </el-scrollbar>

      </el-aside>
      <el-container style="width: 100%">
        <el-header class="header-component"><HeaderComponent :isClose="isClose"   @change="change"></HeaderComponent></el-header>
        <el-main class="content_component">
          <ContentComponent></ContentComponent>
        </el-main>
      </el-container>
    </el-container>



<!--    <el-container class="layout-box">-->
<!--      <el-aside width="auto" class="aside"><NavBarComponent :isClose="isClose"></NavBarComponent></el-aside>-->
<!--      <el-main class="content_component"><ContentComponent></ContentComponent></el-main>-->
<!--    </el-container>-->




  </div>

</template>

<style scoped lang="less">

//:deep(.el-aside) {
//  width: auto;
//}


.common-layout {
  width: 100%;
  height: 100vh;
  background-color: #f8fafe;
  //#FAFCFF

  .layout-box {
    height: 100vh;


    .aside {
      width: auto;
      height: 100vh;
      box-shadow: 0 0 5px rgba(0,0,0,0.3);



    }
    //box-shadow: 0 0 5px rgba(0,0,0,0.3);

    .header-component {
      padding: 0;
      margin: 0;
      height: 70px;
      box-shadow: 0 0 5px rgba(0,0,0,0.3);
    }


    .content_component {
      padding: 0;
      height: calc(100vh - 50px);
      overflow-y: auto;
      scrollbar-width: none; /* Firefox, Opera */
      -ms-overflow-style: none; /* IE and Edge */
    }
    .content_component::-webkit-scrollbar {
      display: none;//去除滚动条
    }

  }

}



:deep(.el-scrollbar){
  --el-scrollbar-bg-color: transparent;
  --el-scrollbar-hover-bg-color:transparent;
}

</style>