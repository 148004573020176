import pinyin from "js-pinyin";
pinyin.setOptions({ checkPolyphone: false, charCase: 1 });

//获取当前日期时间
export function getCurrentDataTime() {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let Hours = date.getHours();
  let Minutes = date.getMinutes();
  let Seconds = date.getSeconds();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  if (Hours < 10) {
    Hours = "0" + Hours;
  }
  if (Minutes < 10) {
    Minutes = "0" + Minutes;
  }
  if (Seconds < 10) {
    Seconds = "0" + Seconds;
  }
  return (
    year + "-" + month + "-" + day + " " + Hours + ":" + Minutes + ":" + Seconds
  );
}

//将标准时间转成合适的日期格式
export function transStandardToFormat(theDate) {
  let year = theDate.getFullYear();
  let month = theDate.getMonth() + 1;
  let day = theDate.getDate();
  let Hours = theDate.getHours();
  let Minutes = theDate.getMinutes();
  let Seconds = theDate.getSeconds();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  if (Hours < 10) {
    Hours = "0" + Hours;
  }
  if (Minutes < 10) {
    Minutes = "0" + Minutes;
  }
  if (Seconds < 10) {
    Seconds = "0" + Seconds;
  }
  return (
    year + "-" + month + "-" + day + " " + Hours + ":" + Minutes + ":" + Seconds
  );
}

//把全部的分类数据转换成树形数据
export function transCategoryDataToTree(list, rootValue) {
  const arr = [];

  list.forEach((item) => {
    if (item.categoryPid === rootValue) {
      //找到了匹配的节点
      arr.push(item);

      //当前节点的id 和 当前节点的子节点的pid是相等的
      item.children = transCategoryDataToTree(list, item.categoryId);
      //将子节点赋值给当前节点
    }
  });
  return arr;
}

//把文档中心里的全部的分类数据转换成树形数据
export function transDocumentCategoryDataToTree(list, rootValue) {
  const arr = [];

  list.forEach((item) => {
    if (item.dcPid === rootValue) {
      //找到了匹配的节点
      arr.push(item);

      //当前节点的id 和 当前节点的子节点的pid是相等的
      item.children = transDocumentCategoryDataToTree(list, item.dcId);
      //将子节点赋值给当前节点
    }
  });
  return arr;
}


// 把所有的关键词分类数据转换成树形数据

export function transKeywordClassificationToTree(list, rootValue) {
  const arr = [];

  list.forEach((item) => {
    if (item.keywordPid === rootValue) {
      arr.push(item);
      //   当前节点的id 和当前节点的pid 是相等的
      item.children = transKeywordClassificationToTree(list, item.keywordId);
    }
  });
  return arr;
}




//返回字符串的首字符并转化成小写
export const transStringToCamelChars = (str) => {
  return pinyin.getCamelChars(str).toLocaleLowerCase();
};





//把全部的权限数据转换成树形数据
export function transPermissionDataToTree(list, rootValue) {
  const arr = [];
  // console.log(list);
  list.forEach((item) => {
    // console.log(1111111111111,item)
    if (item.permissionPid === rootValue) {
      // console.log(item);
      //找到了匹配的节点
      arr.push(item);
      //当前节点的id 和 当前节点的子节点的pid是相等的
      item.children = transPermissionDataToTree(list, item.permissionId);
      //将子节点赋值给当前节点
    }
  });
  return arr;
}






//
export function recursionRouter(userRouter, allRouter) {
  let realRouter = [];
  allRouter.forEach((v) => {
    userRouter.forEach((item) => {
      // console.log(item)
      if (item.permissionName === v.meta.name) {
        if (item.children && item.children.length > 0) {
          v.children = recursionRouter(item.children, v.children);
        }
        realRouter.push(v);
      }
    });
  });
  return realRouter;
}
