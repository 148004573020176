import { createRouter, createWebHashHistory } from "vue-router";
import IndexView from "@/views/index/IndexView.vue";
import { ElMessage } from "element-plus";
import { recursionRouter, transPermissionDataToTree } from "@/utils";
import store from "@/store";
import { getUserPermission } from "@/api/permission";
import { ForeignDynamicRoutes } from "@/router/foreign/ForeignDynamicRoutes";
import { DemosticDynamicRoutes } from "@/router/demostic/DemosticDynamicRoutes";
import axios from "axios";

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: IndexView,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/user/LoginView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/user/RegisterView.vue"),
  },
  {
    path: "/change",
    name: "change",
    component: () => import("../views/user/ChangePassword.vue"),
  },
  {
    path: "/index",
    name: "index",
    component: IndexView,
    redirect: "/index/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/home/IndexView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

//获取指定用户的权限信息
export const getUserPermissionInfo = async () => {
  //在获取用户权限之前，判断一下有没有token
  if (localStorage.getItem("token")) {
    //如果token存在的话，向后端发送请求获取用户权限
    //发送请求获取用户权限信息
    try {
      await axios
        .post(
          "/api/chinese-service/permission/getUserPermission",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.code === -1) {
            ElMessage.error("获取权限信息失败");
          } else {
            let currentRoutes = router.getRoutes().map((route) => route.name);
            // console.log(res.data)
            let thePermissionTree = transPermissionDataToTree(
              res.data.data,
              "0"
            );
            let data = [];
            if (store.state.currentOperationStage === "内贸") {
              // console.log(11111,thePermissionTree)
              data = recursionRouter(thePermissionTree, DemosticDynamicRoutes);
            } else if (store.state.currentOperationStage === "外贸") {
              // console.log(thePermissionTree)
              // console.log(ForeignDynamicRoutes)
              data = recursionRouter(thePermissionTree, ForeignDynamicRoutes);
            }
            if (data && data.length > 0) {
              data.forEach((item) => {
                if (!currentRoutes.includes(item.name)) {
                  router.addRoute("index", item);
                }
              });
              //将比对完的权限数组往vuex中也存储一份
              store.commit("setNavigationData", data);
            }
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            ElMessage.error("登录状态失效，请重新登录");
            localStorage.removeItem("token");
            router.push("/login");
          }
        });
    } catch (err) {
      console.log(err);
    }
  }
};

//路由守卫
router.beforeEach(async (to, from, next) => {
  //验证token
  let token = localStorage.getItem("token");
  if (
    token &&
    (to.path === "/login" ||
      to.path === "/register" ||
      to.path === "/change" ||
      to.path === "/")
  ) {
    next("/index");
  } else if (
    !token &&
    to.path !== "/login" &&
    to.path !== "/register" &&
    to.path !== "/change" &&
    to.path !== "/"
  ) {
    ElMessage.warning("登录状态失效，请重新登录");
    next("/login");
  } else {
    await getUserPermissionInfo();
    next();
  }
});

export default router;
