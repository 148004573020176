<script setup>
import {Search} from "@element-plus/icons-vue";
import {useStore} from "vuex";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import {Expand, Fold} from "@element-plus/icons-vue";
import {onBeforeUnmount, onMounted, onUnmounted, ref} from "vue";
import router from "@/router";
// import {setBaseURL} from '@/utils/http';


const store = useStore()


//接受父组件传来的值
const props = defineProps({
  isClose:{
    type:Boolean
  }
})


//一直在更新时间
const time = ref(new Date().toLocaleTimeString())
const updateTime = ()=>{
  time.value = new Date().toLocaleTimeString()
}


onMounted(()=>{
  const intervalId = setInterval(updateTime,1000)
  onUnmounted(()=>clearInterval(intervalId))

})


//子组件传给父组件值，让父组件执行一次change函数
const emit = defineEmits(['change'])
const change = ()=>{
  emit('change')
}
// 绑定搜索框
const search = ref('')
// 定义设置本身是关闭状态
const setting = ref(false)


// 肤色转换
const changeColor = () => {
  disappear.value = !disappear.value
  appear.value = !appear.value
}
// 暗光隐藏
const disappear = ref(true)
// 日光隐藏
const appear = ref(false)








const changeForeignTrade = () => {
  store.commit("SET_FOREIGN_TRADE");
  // setBaseURL(true);
  if (router.currentRoute.value.name === "home") {
    window.location.reload();
  } else {
    router.push('/index').then(() => window.location.reload());
  }
};

const changeDomesticTrade = () => {
  store.commit("SET_DOMESTIC_TRADE");
  // console.log(1111111111111111111);
  // setBaseURL(false);
  if (router.currentRoute.value.name === "home") {
    window.location.reload();
  } else {
    router.push('/index').then(() => window.location.reload());
  }
};
</script>

<template>
  <div class="header"  style="position: relative">
    <div class="iconHead">
      <i class="icon iconfont icon-caidan" @click="change"></i>
    </div>

    <!--   搜索框 -->
    <el-input
        v-model="search"
        class="inputEl"
        placeholder="点击查询"
        :prefix-icon="Search"
    />


<!--    翻译 消息通知 设置 亮度 登录人员姓名-->
    <div class="iContentTop" style="position: absolute;right: 0">
<!--      翻译-->
      <div class="iconContentTop" >
        <i class="icon iconfont icon-language"></i>
      </div>
<!--      消息通知-->
      <div class="iconContentTop">
        <i class="icon iconfont icon-xiaoxitongzhi"></i>
      </div>
<!--      设置-->
      <div class="iconContentTop">
        <i class="icon iconfont icon-shezhi" @click="setting = true"></i>
      </div>
<!--      调节光暗-->
      <div class="iconContentTop">
        <i class="icon iconfont icon-guangan-an" @click="changeColor" v-show="disappear"></i>
        <i class="icon iconfont icon-guangan-guang" @click="changeColor" v-show="appear"></i>
      </div>

      <div>
        <el-button v-if="store.state.currentOperationStage === '内贸'" class="headButton" @click="changeForeignTrade">前往外贸后台</el-button>
        <el-button v-else class="headButton" @click="changeDomesticTrade">前往内贸后台</el-button>
      </div>

    </div>

<!--点击设置 弹出抽屉-->
    <el-drawer
    v-model="setting"
    title="主题设置"
    direction="rtl"
    size="20%"
    >

    </el-drawer>


  </div>

</template>

<style scoped lang="less">


@import "../../styleSheet.css";

.headButton {
  border-color: transparent;
  background: transparent;
}


</style>
