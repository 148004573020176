import axios from '../utils/http'


//向后端发送添加的名称，，，，判断一下名称是否被添加过了
export const isExistedThePermissionName = data=>axios.post('/permission/isExistedThePermissionName' , data)


//向后端发送请求添加一条主权限数据√
export const increasePermissionDataOne = data =>axios.post('/permission/increasePermissionDataOne' , data)


//向后端发送请求添加一条子权限数据√
export const increasePermissionDataSon = data =>axios.post('/permission/increasePermissionDataSon' , data)



//向后端发送请求获取所有的权限数据√
export const getAllPermissionDataInfo = ()=>axios.get('/permission/getAllPermissionDataInfo')



//向后端发送请求删除一条权限数据√
export const deletePermissionDataInfo = data =>axios.post('/permission/deletePermissionDataInfo' , data)




//向后端发送请求修改一条权限值√
export const updatePermissionDataByID = data =>axios.post('/permission/updatePermissionDataByID' , data)
























//获取内部用户权限√
export const getUserPermission = ()=>axios.post('/permission/getUserPermission')


